interface LoadingState {
    isCityBrowseLoading?: boolean;
    isFeLoading: boolean | undefined;
    isOrganizerArticlesLoading?: boolean | undefined;
    isProfilesLoading?: boolean | undefined;
    isSearchLoading?: boolean | undefined;
}

export const PLACES: { [key: string]: string } = {
    ATLANTA: '85936429',
    AUSTIN: '101724577',
    BALTIMORE_MD: '85949461',
    BOSTON_MA: '85950361',
    CHARLOTTE_NC: '85981333',
    CHICAGO: '85940195',
    DALLAS: '101724385',
    DENVER_CO: '85928879',
    DETROIT_MI: '85951091',
    DUBLIN_IE: '101751737',
    HOUSTON: '101725629',
    LAS_VEGAS_NV: '85974801',
    LONDON: '101750367',
    LOS_ANGELES: '85923517',
    MELBOURNE_AU: '101933229',
    MIAMI: '85933669',
    MINNEAPOLIS_MN: '85969169',
    NEW_YORK: '85977539',
    ORLANDO: '85933091',
    PHILADELPHIA_PA: '101718083',
    PHOENIX_AZ: '85917479',
    SAN_DIEGO_CA: '85922227',
    SAN_FRANCISCO: '85922583',
    SAN_JOES_CA: '85922347',
    SEATTLE_WA: '101730401',
    SYDNEY_AU: '101932003',
    TAMPA_FL: '85931789',
    TORONTO_CA: '101735835',
    VANCOUVER_CA: '101741075',
    WASHINGTON_DC: '85931779',
    STPAUL: '85953191',
};

export const currentLoadingState = (loadingState: LoadingState | undefined) => {
    if (
        loadingState &&
        !loadingState.isFeLoading &&
        !loadingState.isSearchLoading &&
        !loadingState.isCityBrowseLoading
    ) {
        return false;
    } else return true;
};

/****************************** Home Page Featured Seasonal Component ********************************/
export interface HOLIDAY_FEATURED {
    [key: string]: {
        title: string;
        subTitle: string;
        description: string;
        viewMoreUrl: string;
        collectionUrl?: string;
        categoryTag: string[];
        locations: {
            [key: string]: string[];
        };
    };
}

export const SEASONAL_HOLIDAY_FEATURED: HOLIDAY_FEATURED = {
    HALLOWEEN: {
        title: 'GET OUT, GET OUT',
        subTitle: 'WHEREVER YOU ARE',
        description: 'This Halloween, it’s all treats—and a few tricks 🎃',
        viewMoreUrl:
            '/b/local/holiday/halloweenhaunt/?utm_source=halloween-featured',
        categoryTag: ['EventbriteSubCategory/16004'],
        locations: {
            country: ['USA', 'United States', 'Australia'],
            cityIds: ['101750367'], // London location
        },
    },
    EOY: {
        title: 'Holiday festivities',
        subTitle: '',
        description: '',
        viewMoreUrl: '/b/local/holiday/?utm_source=homepage-featured',
        categoryTag: ['EventbriteCategory/116'],
        locations: {
            country: [''],
            cityIds: [''],
        },
    },
    NYE: {
        title: 'Cheers to the New Year',
        subTitle: '',
        description:
            'Ring in 2024 in style. The countdown is on with unique things to do near you.',
        viewMoreUrl:
            '/b/local/holiday/new-years-eve/?utm_source=homepage-featured',
        collectionUrl: '',
        categoryTag: ['EventbriteSubCategory/16009'],
        locations: {
            country: [''],
            cityIds: [''],
        },
    },
    VALENTINE: {
        title: "Eventbrite's picks ❤️ ",
        subTitle: '',
        description: '',
        viewMoreUrl: '',
        categoryTag: [],
        locations: {
            country: [''],
            cityIds: [''],
        },
    },
    SPD: {
        title: "Eventbrite's picks",
        subTitle: '',
        description: '',
        viewMoreUrl: '',
        categoryTag: [],
        locations: {
            country: [''],
            cityIds: [''],
        },
    },
};

export const COLLECTION_SURFACE_MAPPING = {
    Homepage: ['ccmxcyfh'],
    Nightlife: ['ccmxcygq'],
    Seasonal: ['ccmxcyph'],
};

export function isLocationEnable(
    holiday: string,
    country: string | undefined,
    placeId: string | null | undefined,
    currentPlace: string,
) {
    // check if component is enable for the country or city
    if (
        (country &&
            SEASONAL_HOLIDAY_FEATURED[holiday].locations.country.indexOf(
                country,
            ) > -1) ||
        (placeId &&
            SEASONAL_HOLIDAY_FEATURED[holiday].locations.cityIds.indexOf(
                placeId,
            ) > -1) ||
        currentPlace == 'Online'
    )
        return true;

    return false;
}

export const NYE_COLLECTION_MAP: { [key: string]: string } = {
    [PLACES.NEW_YORK]: 'c/new-york-new-years-eve-events-cwtgckw/',
    [PLACES.CHICAGO]: '/c/chicago-new-years-eve-events-cwtgcwp/',
    [PLACES.LOS_ANGELES]: '/c/los-angeles-new-years-eve-events-cwtgcpk/',
    [PLACES.WASHINGTON_DC]: '/c/washington-dc-new-years-eve-events-cwtgcrb/',
    [PLACES.SAN_FRANCISCO]: '/c/san-francisco-new-years-eve-events-cwtgmcb/',
    [PLACES.LONDON]: '/c/new-years-eve-london-events-cwtppzk/',
    [PLACES.ATLANTA]: '/c/atlanta-new-years-eve-events-cwtgcxk/',
    [PLACES.DALLAS]: '/c/dallas-new-years-eve-events-ccmxcwfm/',
    [PLACES.HOUSTON]: '/c/houston-new-years-eve-events-ccmxcwgc/',
    [PLACES.MIAMI]: '/c/miami-new-years-eve-events-cwtgcwb/',
    [PLACES.ORLANDO]: '/c/orlando-new-years-eve-events-ccmxcwdx/',
};

const HOLIDAY_HAPPENINGS_CITIES = [
    'NEW_YORK',
    'LONDON',
    'LOS_ANGELES',
    'WASHINGTON_DC',
    'CHICAGO',
    'MIAMI',
    'ATLANTA',
    'TORONTO',
    'SAN_FRANCISCO',
    'MELBOURNE',
    'SYDNEY',
    'BOSTON',
    'HOUSTON',
    'VANCOUVER',
    'PHILADELPHIA',
];

export const HOLIDAY_HAPPENINGS_CITIES_IDS: string[] =
    HOLIDAY_HAPPENINGS_CITIES.map((city) => PLACES[city]);
export const NewOrleansPlaceId = '85948111';
export const AustinPlaceId = '101724577';
